import Modal from 'react-modal';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';
import { SolidarityChallengeService } from '../services/Services';
import DOMPurify from 'dompurify'
export function PhotoPopup(props) {

    const [dataUri, setDataUri] = useState(null)
    const [image, setImage] = useState(null)
    // const [retrySnap, setRetrySnap] = useState('false')
    const domEl = useRef(null);
    let customModalStylesPhoto = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '500px',
            height: '550px',
            marginRight: '-50%',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden'
        },

    };
    function handleTakePhoto(dataUri) {
        setImage(null)

        //  console.log(dataUri)
        // Do stuff with the photo...

        //  let setPopup = this.setPublishPopup;
        //    let foto = document.getElementById('mask_container')
        //   html2canvas(foto).then(function (canvas) {

        // setRetrySnap('false')
        let body = {
            image: dataUri
        };
        setDataUri(dataUri)
        ///  setPopup(false)
        //  sc.getUrlToShare(body)






        /*    const link = document.createElement('a')
            link.href = canvas.toDataURL()
            link.download = 'myframe-changemakerschallenge.png'
            link.click()
            const link2 = document.createElement('a')
            link2.href = "https://kering.workplace.com/groups/676519715867618"
            link2.target = '_blank'
            link2.click()*/
        // window.open('https://kering.workplace.com/sharer.php?display=page&u=' + response.data, 'popUpWindow', 'height=800,width=700,left=150,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        //    });
        setTimeout(() => {

            //  setRetrySnap('false')
            let sc = new SolidarityChallengeService()
            sc.getUrlToShare(body, setImage)
        }, 200);

    }

    function download() {

        const link = document.createElement('a')
        link.href = image
        link.download = "image.jpg"
        link.click()

    }

    function retry() {
        setDataUri(null)
        setImage(null)

    }

    function close() {
        setDataUri(null)
        setImage(null)
        props.setPublishPopup(false)
    }

    return (<Modal
        className={'photo_popup'}
        isOpen={props.publishPopup}
        onAfterOpen={null}
        onRequestClose={() => close()}
        style={customModalStylesPhoto}
        appElement={document.getElementById('root') || undefined}
    >
        <div className="modal_content_container ">
            <div className="modal_header flex">
                {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                    <div className="modal_content_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.getTranslationByKey('CMC.SHARE.TEXT').replace('{0}', 'Viva Engage')) }}></div>
                    :
                    <div className="modal_content_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.getTranslationByKey('CMC.SHARE.TEXT').replace('{0}', 'workplace')) }}></div>
                }

                {/*       <div className="modal_content_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.getTranslationByKey('CMC.SHARE.TEXT').replace('{0}', 'lexiang')) }}></div>
             */}   <div className="close_button" >
                    <svg onClick={() => close()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </div>

            <div >
                <div id='mask_container' className='mask_container' ref={domEl}>
                    {!dataUri ?
                        <Camera
                            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                            idealResolution={{ width: 1600, height: 1600 }}
                            idealFacingMode={FACING_MODES.USER}
                            imageType={IMAGE_TYPES.JPG}
                        //  imageType={IMAGE_TYPES.PNG}
                        />
                        :
                        (image ? < img className='photo_image' src={image} /> : <div className="loader-cont-popup" ><div className="loader-cont"><div className="loader"> </div></div></div>)}
                    {/*} <div className='photo_image' style={{ backgroundImage: 'url(' + dataUri + ')' }}>*/}
                    {!dataUri ? <div className='flex'>
                        <div id='mask' >                        </div>
                        <div className='white_bg'></div>
                    </div> : ''}
                </div>
                {!dataUri ? '' : <><div Style={"place-content: center;"} className='flex'>
                    <button className='button_retry' onClick={() => retry()} disabled={!dataUri || !image ? true : false}> {props.getTranslationByKey('CMC.RETRY')}</button>
                    <button className='button_stamp' onClick={() => download()} disabled={!dataUri || !image ? true : false}> {props.getTranslationByKey('CMC.DOWNLOAD')}</button>
                    {/*}   <a className='button_share' href='https://kering.workplace.com/groups/676519715867618'> lexiang</a>*/}
                </div >

                    {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                        <a className='link_share' href='https://engage.cloud.microsoft/main/org/kering.onmicrosoft.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI0MzM2NTc1MjgzMyJ9/new' target='_blank'> {props.getTranslationByKey('CMC.SHARE.LINK').replace('{0}', 'Viva Engage')}</a>
                        :
                        <a className='link_share' href='https://kering.workplace.com/groups/676519715867618' target='_blank'>{props.getTranslationByKey('CMC.SHARE.LINK').replace('{0}', 'workplace')}</a>
                    }


                    {/*} */}   </>
                }

            </div>
        </div>
    </Modal >)
}