import React, { Component } from "react";
import Navbar from "./Navbar"
import { ActivityService, SolidarityChallengeService } from "../services/Services";
import ProgressBar from "./ProgressBar";
import Modal from 'react-modal';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from "react-select";
import { UseMessage } from './Alert';
import { Pagination } from "@mui/material";

import DOMPurify from 'dompurify'
import { PhotoPopup } from "./PhotoPopup";

class Homepage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sc: new SolidarityChallengeService(),
            as: new ActivityService(),
            loading: true,
            leaderboard_loading: true,
            user: props.user,
            global: true,
            today: false,
            week: true,
            month: false,
            everyone: true,
            joined: false,
            openJoinChallengeModal: false,
            activityModalStatus: false,
            trackingModalStatus: false,
            seeAllTeamModalStatus: false,
            seeAllLeaderboardModalStatus: false,
            challenge: '',
            leaderboard: [],
            total_leaderboard: [],
            modal_leaderboard: [],
            filtered_leaderboard: [],
            total_filtered_leaderboard: [],
            total_filtered_my_team: [],
            total_my_team: [],
            filter_loading: false,
            search_text: '',
            currentPage: 1,
            page_items: 10,
            activities: [],
            measurements: [],
            activity_date: new Date(),
            my_trackings: [],
            my_team: [],
            modal_my_team: [],
            filtered_my_team: [],
            recently_joined: [],
            recently_joined_loading: false,
            selectedActivity: '',
            selectedActivityLabel: '',
            measurementValue: '0.0',
            measurementSelectValue: '',
            measurementSelectLabel: '',
            activity_loader: false,
            tracking_loader: false,
            balance: '',
            meals: '',
            infoPopup: false,
            my_position: '',
            conversion_info: [],
            publishPopup: false,
            is_privacy_policy: sessionStorage.getItem('privacy_policy') === "true" ? true : false,
            global_recently_joined: [],
            selectedTracking: '',
            modal_delete_tracking: false,
            challenge_id: '',
            //   tmpImg: ''
            regions: [],
            today_region: false,
            week_region: true,
            month_region: false,
            region_loading: false,
        }

        this.setLoading = this.setLoading.bind(this)
        this.setFilterLoading = this.setFilterLoading.bind(this)
        this.setUser = this.setUser.bind(this)
        this.handleSwitch = this.handleSwitch.bind(this)
        this.handleButton = this.handleButton.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.setActivityDate = this.setActivityDate.bind(this)
        this.setActivities = this.setActivities.bind(this)
        this.setChallenge = this.setChallenge.bind(this)
        this.setLeaderboard = this.setLeaderboard.bind(this)
        this.setRecentlyJoined = this.setRecentlyJoined.bind(this)
        this.setRecentlyJoinedLoading = this.setRecentlyJoinedLoading.bind(this)
        this.setLeaderboardLoading = this.setLeaderboardLoading.bind(this)
        this.handleActivitySelect = this.handleActivitySelect.bind(this)
        this.setLoadingAddActivity = this.setLoadingAddActivity.bind(this)
        this.setMyTeam = this.setMyTeam.bind(this)
        this.setTrackings = this.setTrackings.bind(this)
        this.setLoadingTrackings = this.setLoadingTrackings.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleText = this.handleText.bind(this)
        this.handleChangePageTeam = this.handleChangePageTeam.bind(this)
        this.handleTextTeam = this.handleTextTeam.bind(this)
        this.getMeasurements = this.getMeasurements.bind(this)
        this.setBalance = this.setBalance.bind(this)
        this.setConversionInfo = this.setConversionInfo.bind(this)
        this.getTime = this.getTime.bind(this)
        this.setMeals = this.setMeals.bind(this)
        this.setInfoPopup = this.setInfoPopup.bind(this)
        this.connectStrava = this.connectStrava.bind(this)
        this.getChallengeLeaderboard = this.getChallengeLeaderboard.bind(this)
        this.getBalance = this.getBalance.bind(this)
        this.getChallengeInfo = this.getChallengeInfo.bind(this)
        this.setPublishPopup = this.setPublishPopup.bind(this)
        //this.handleTakePhoto = this.handleTakePhoto.bind(this)
        this.setPrivacyPolicy = this.setPrivacyPolicy.bind(this)
        this.getMyTrackings = this.getMyTrackings.bind(this)
        this.setMyPosition = this.setMyPosition.bind(this)
        this.setChalengeId = this.setChalengeId.bind(this)

        this.setRegionLeaderboard = this.setRegionLeaderboard.bind(this)
        this.handleRegionButton = this.handleRegionButton.bind(this)
        this.setRegionLoading = this.setRegionLoading.bind(this)
        this.setTeamLeaderboard = this.setTeamLeaderboard.bind(this)

        /*   this.getUserImage = this.getUserImage.bind(this)
           this.setImg = this.setImg.bind(this)
   */
    }

    componentDidMount() {
        this.setLoading(true)

        //this.state.sc.getTranslation().then(() => {
        if (this.state.joined) {
            if (this.props.user && this.props.user.modules && this.props.user.modules.gsc && this.props.user.modules.gsc.challenges && this.props.user.modules.gsc.challenges.length > 0) {
                this.state.sc.getMeals(this.props.user.modules.gsc.challenges[0].id, this.setMeals)
            }
        }
        this.setLeaderboardLoading(true)
        let filters = {}
        if (!this.props.user.modules.gsc.challenges[0].is_active)
            filters = {
                "is_global": this.state.global,
                "time": ''
            }
        else
            filters = {
                "is_global": this.state.global,
                "time": this.state.today == true ? 'day' : (this.state.week == true ? 'week' : '')
            }
        let filters_region = {}
        if (!this.props.user.modules.gsc.challenges[0].is_active)
            filters_region = {
                "time": ''
            }
        else
            filters_region = {
                "time": this.state.today == true ? 'day' : (this.state.week == true ? 'week' : '')
            }

        /**/

        let path = window.location.href.split('staging/')
        let original_path = path[0] + 'staging/'
        if (window.location.hostname == 'changemakerschallenge.gucci.cn') {
            path = window.location.href.split('.cn/')
            original_path = path[0] + '.cn/'
        } else if (window.location.hostname == 'changemakerschallenge.gucci.com') {
            path = window.location.href.split('.com/')
            original_path = path[0] + '.com/'
        }
        if (path.length > 1 && path[1] === '?privacy_policy') {
            this.setPrivacyPolicy(true)
            window.history.pushState('homepage', 'homepage', original_path)
        }
        /**/

        sessionStorage.setItem('privacy_policy', false)
        /* */

        if (this.props.user && this.props.user.modules && this.props.user.modules.gsc && this.props.user.modules.gsc.challenges && this.props.user.modules.gsc.challenges.length > 0) {
            let challenge_id = this.props.user.modules.gsc.challenges[0].id

            this.setChalengeId(challenge_id)
            this.state.sc.getChallengeLeaderboard(challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
            this.state.sc.getChallengeRegionLeaderboard(challenge_id, filters_region, this.setRegionLeaderboard, this.setLeaderboardLoading)
            this.state.sc.getMyPosition(challenge_id, filters, this.setMyPosition)
            this.state.sc.getChallengeInfo(challenge_id, this.setChallenge)
            this.state.sc.getRecentlyJoined(challenge_id, !this.state.everyone, this.setRecentlyJoined, this.setRecentlyJoinedLoading)
            this.state.sc.getBalance(challenge_id, this.setBalance, this.setLoading)
            this.state.as.getActivities(this.setActivities, challenge_id)
            this.state.sc.getConversionInfo(this.setConversionInfo, challenge_id)
        }
        //this.state.sc.getMyTeam(this.setMyTeam)
        this.setUser(this.props.user)
        //this.state.sc.getUserInfo(this.setUser, this.setLoading)
        //})

        setTimeout(() => {

            if (this.props.scroll && window.screen.width < 600) {
                let coin = document.getElementById('coin')
                let value = coin.offsetTop - 100
                let a = document.getElementsByClassName('boxes_container_animation')
                a[0].scrollTo({ left: 0, top: value, behavior: 'smooth' })
            }
        }, 2000);

        /*   setTimeout(() => {

               if (this.props.scroll) {
                   const element = document.getElementById('coin');
                   if (element) {
                       // 👇 Will scroll smoothly to the top of the next section
                       element.scrollIntoView({ behavior: 'smooth' });
                   }
               }
           }, 2000);*/

        if (this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active) {
            this.setState({
                today: false,
                week: false,
                month: true
            })
        }
    }
    /*  setImg(s) {
          this.setState({
              tmpImg: s
          })
          console.log(s)
      }
      async getUserImage() {

          // this.state.sc.getUserImage(this.setImg)
          return this.state.tmpImg
      }*/
    async setLoading(val) {
        this.setState({
            loading: val
        })
    }
    async setFilterLoading(val) {
        this.setState({
            filter_loading: val
        })
    }
    setPublishPopup(val) {
        this.setState({
            publishPopup: val
        })
    }
    setChalengeId(id) {
        this.setState({
            challenge_id: id
        })
    }
    setTeamLeaderboard(id) {
        this.setState({
            teamLeaderboard: id
        })
    }


    connectStrava(id) {
        this.setLoading(true)
        this.state.sc.connectToStrava(id).then(() => this.setLoading(false))
    }
    handleChangePage(e, newPage) {
        this.setFilterLoading(true)

        setTimeout(() => {


            let val = this.state.page_items * (newPage - 1)
            let t = []
            if (this.state.search_text === '') {
                t = this.state.leaderboard.slice(val, this.state.page_items * newPage)
                this.setState({
                    modal_leaderboard: t,
                    filter_loading: false
                })
            }
            else {
                t = this.state.total_filtered_leaderboard.slice(val, this.state.page_items * newPage)

                setTimeout(() => {

                    this.setState({
                        filtered_leaderboard: t,
                        filter_loading: false
                    })
                }, 500);
            }

            this.setState({
                currentPage: newPage
            })
        }, 500);
    }
    handleChangePageTeam(e, newPage) {
        this.setFilterLoading(true)

        setTimeout(() => {


            let val = this.state.page_items * (newPage - 1)
            let t = []
            if (this.state.search_text === '') {
                t = this.state.my_team.slice(val, this.state.page_items * newPage)
                this.setState({
                    modal_my_team: t,
                    filter_loading: false
                })
            }
            else {
                t = this.state.total_filtered_my_team.slice(val, this.state.page_items * newPage)

                setTimeout(() => {

                    this.setState({
                        filtered_my_team: t,
                        filter_loading: false
                    })
                }, 500);
            }

            this.setState({
                currentPage: newPage
            })
        }, 500);
    }
    handleText(e) {
        this.setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_leaderboard = []

            tmp = (this.state.total_leaderboard.filter(r => r.user.last_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase()) ||
                (r.user.first_name.toLowerCase() + ' ' + r.user.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
                r.user.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || r.user.location.toLowerCase().includes(e.target.value.toLowerCase())
            ))
            tmp_leaderboard = tmp.slice(0, this.state.page_items)
            this.setState({
                search_text: e.target.value,
                currentPage: 1,
                filtered_leaderboard: tmp_leaderboard,
                total_filtered_leaderboard: tmp,
                filter_loading: false
            })
        }, 1000);


    }
    handleTextTeam(e) {
        this.setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_my_team = []

            tmp = (this.state.total_my_team.filter(r => r.last_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase()) ||
                (r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
                r.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || r.location.toLowerCase().includes(e.target.value.toLowerCase())
            ))
            tmp_my_team = tmp.slice(0, this.state.page_items)
            this.setState({
                search_text: e.target.value,
                currentPage: 1,
                filtered_my_team: tmp_my_team,
                total_filtered_my_team: tmp,
                filter_loading: false
            })
        }, 1000);


    }
    setUser(user) {
        if (user.modules && user.modules.gsc && user.modules.gsc.challenges)
            user.modules.gsc.challenges.map((c) => {
                if (c.joined)
                    this.setState({
                        joined: true
                    })


            })
        this.setState({
            user: user,
        })
    }

    setActivities(activities) {
        this.setState({
            selectedActivity: activities[0].id,
            selectedActivityLabel: activities[0].name,
            activities: activities,
            measurements: activities[0].unit,
            measurementSelectValue: activities[0].unit[0].id,
            measurementSelectLabel: activities[0].unit[0].label,
        })
    }


    setChallenge(challengeInfo) {
        this.setState({
            challenge: challengeInfo
        })
    }
    setLeaderboard(leaderboard) {

        this.setState({
            leaderboard: leaderboard,
            total_leaderboard: leaderboard,
            filtered_leaderboard: leaderboard,
            modal_leaderboard: leaderboard
        })
    }

    setRegionLeaderboard(leaderboard) {
        this.setState({
            regions: leaderboard,
            //total_leaderboard: leaderboard,
            //filtered_leaderboard: leaderboard,
            //modal_leaderboard: leaderboard
        })
    }

    setMyPosition(position) {
        this.setState({
            my_position: position,
        })
    }

    setLeaderboardLoading(val) {
        this.setState({
            leaderboard_loading: val
        })
    }
    handleSwitch() {
        let g = !this.state.global

        let filters = {
            "is_global": g,
            "time": this.state.today == true ? 'day' : (this.state.week == true ? 'week' : '')
        }
        if (this.state.joined) {
            this.setState({
                global: g,
                leaderboard_loading: true
            })
            this.state.sc.getMyPosition(this.state.challenge_id, filters, this.setMyPosition)
            this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
        }
        else
            this.setState({
                global: g,

            })
    }
    handleButton(button_name) {
        switch (button_name) {
            case 'today':
                if (!this.state.today) {
                    this.setState({
                        today: !this.state.today,
                        week: false,
                        month: false,

                    })
                    //  if (this.state.joined) {
                    this.setState({
                        leaderboard_loading: true
                    })
                    let filters = {
                        "is_global": this.state.global,
                        "time": "day"
                    }
                    this.state.sc.getMyPosition(this.state.challenge_id, filters, this.setMyPosition)
                    this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
                    //    }
                }
                /*   else
                        this.setState({
                            today: true,
                            week: false,
                            month: false
                        })*/
                break;
            case 'week':
                if (!this.state.week) {
                    this.setState({
                        week: !this.state.week,
                        today: false,
                        month: false,

                    })
                    // if (this.state.joined) {
                    this.setState({
                        leaderboard_loading: true
                    })
                    let filters = {
                        "is_global": this.state.global,
                        "time": "week"
                    }
                    this.state.sc.getMyPosition(this.state.challenge_id, filters, this.setMyPosition)
                    this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
                    //  }
                }
                /*  else {
                      this.setState({
                          week: true,
                          today: false,
                          month: false,
                      })
                  }*/
                break;
            case 'month':
                if (!this.state.month) {
                    this.setState({
                        month: !this.state.month,
                        week: false,
                        today: false,

                    })
                    //  if (this.state.joined) {
                    this.setState({
                        leaderboard_loading: true
                    })
                    let filters = {
                        "is_global": this.state.global,
                        "time": ""
                    }
                    this.state.sc.getMyPosition(this.state.challenge_id, filters, this.setMyPosition)
                    this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
                    //   }
                }
                /*  else {
                      this.setState({
                          month: true,
                          week: false,
                          today: false
                      })
                  }*/
                break;
            case 'everyone':
                if (!this.state.everyone) {
                    this.setState({
                        everyone: true,
                        recently_joined_loading: true,
                    })
                    this.reloadRecentlyJoined(false)
                }
                break;
            case 'your_team':
                if (this.state.everyone) {
                    this.setState({
                        everyone: false,
                        recently_joined_loading: true,
                    })
                    this.reloadRecentlyJoined(true)
                }
                break;
            default:
                break
        }

    }

    handleRegionButton(button_name) {
        switch (button_name) {
            case 'today':
                if (!this.state.today_region) {
                    this.setState({
                        today_region: !this.state.today_region,
                        week_region: false,
                        month_region: false,
                        region_loading: true
                    })
                    let filters = {
                        "time": "day"
                    }
                    this.state.sc.getTeamLeaderboard(this.state.challenge_id, filters).then((data) => {
                        this.setTeamLeaderboard(data)
                        this.setRegionLoading(false)
                    })

                }
                break;
            case 'week':
                if (!this.state.week_region) {
                    this.setState({
                        week_region: !this.state.week_region,
                        today_region: false,
                        month_region: false,
                        region_loading: true
                    })
                    let filters = {
                        "time": "week"
                    }
                    this.state.sc.getChallengeRegionLeaderboard(this.state.challenge_id, filters, this.setRegionLeaderboard, this.setRegionLoading)
                }
                break;
            case 'month':
                if (!this.state.month_region) {
                    this.setState({
                        month_region: !this.state.month_region,
                        week_region: false,
                        today_region: false,
                        region_loading: true
                    })
                    let filters = {
                        "time": ""
                    }

                    this.state.sc.getChallengeRegionLeaderboard(this.state.challenge_id, filters, this.setRegionLeaderboard, this.setRegionLoading)

                }
                break;
            default:
                break
        }

    }

    handleModal(val, modal, activity) {
        switch (modal) {
            case 'join':
                if (!val) {
                    this.setState({
                        //openJoinChallengeModal: val
                    })
                } else {
                    this.setState({
                        //openJoinChallengeModal: val,
                        leaderboard_loading: true
                    })
                    if (!this.state.joined)
                        this.setState({
                            joined: true
                        })
                    let filters = {
                        "is_global": this.state.global,
                        "time": (this.state.today ? 'day' : (this.state.week ? 'week' : ''))
                    }

                    this.state.sc.getMyPosition(this.state.challenge_id, filters, this.setMyPosition)
                    this.state.sc.joinChallenge(filters, this.state.challenge_id, this.getChallengeLeaderboard, this.setLeaderboardLoading)
                }
                break;
            case 'activity':

                this.setState({
                    activityModalStatus: val,
                    selectedActivity: this.state.activities[0].id,
                    selectedActivityLabel: this.state.activities[0].name,
                    measurements: this.state.activities[0].unit,
                    measurementSelectValue: this.state.activities[0].unit[0].id,
                    measurementSelectLabel: this.state.activities[0].unit[0].label,
                    activity_date: new Date(),
                    measurementValue: '0'
                })

                break;
            case 'tracking':
                if (val) {
                    this.setState({
                        trackingModalStatus: val,
                        tracking_loader: true
                    })
                    this.getMyTrackings()
                } else {
                    this.setState({
                        trackingModalStatus: val
                    })
                }
                break;
            case 'see_all_leaderboard':
                let t = this.state.leaderboard.slice(0, this.state.page_items)
                this.setState({
                    seeAllLeaderboardModalStatus: val,
                    modal_leaderboard: t
                })
                if (!val) {
                    this.setState({
                        currentPage: 1,
                        modal_leaderboard: this.state.total_leaderboard,
                        search_text: ''
                    })
                }
                break;
            case 'see_all_team':
                let team = this.state.my_team.slice(0, this.state.page_items)
                this.setState({
                    seeAllTeamModalStatus: val,
                    modal_my_team: team
                })
                if (!val) {
                    this.setState({
                        currentPage: 1,
                        modal_my_team: this.state.my_team,
                        search_text: ''
                    })
                }
                break;
            case 'delete':
                this.setState({
                    selectedTracking: activity,
                    modal_delete_tracking: val,
                    trackingModalStatus: !val
                })

                break;
            default:
                break;
        }
    }

    setActivityDate(date) {
        this.setState({
            activity_date: date
        })
    }

    setMyTeam(team) {
        this.setState({
            my_team: team,
            filtered_my_team: team,
            total_my_team: team
        })
    }

    setRecentlyJoined(recently_joined) {
        if (this.state.global_recently_joined.length > 0) {
            this.setState({
                recently_joined: recently_joined
            })
        } else {
            this.setState({
                recently_joined: recently_joined,
                global_recently_joined: recently_joined
            })
        }
    }

    setRecentlyJoinedLoading(val) {
        this.setState({
            recently_joined_loading: val
        })
    }

    setRegionLoading(val) {
        this.setState({
            region_loading: val
        })
    }

    reloadRecentlyJoined(val) {
        this.state.sc.getRecentlyJoined(this.state.challenge_id, val, this.setRecentlyJoined, this.setRecentlyJoinedLoading)
    }

    formatData(data) {
        let new_date = new Date(data)
        let today = new Date()

        let differenceDays = this.datediff(today, new_date);
        let toStringDate = ''

        if (differenceDays === 0) {
            toStringDate = this.getTranslationByKey('CMC.TODAY');
        } else if (differenceDays === -1) {
            toStringDate = this.getTranslationByKey('CMC.YESTERDAY');
        } else {
            toStringDate = new_date.getDate().toString() + "/" + (new_date.getMonth() + 1).toString() + "/" + new_date.getFullYear().toString()
        }

        return toStringDate
    }

    getData(date) {

        let new_date = new Date(date)
        let toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + "-" + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    async setLoadingAddActivity(val) {
        this.setState({
            activity_loader: val
        })
    }

    handleActivitySelect(event) {
        this.setLoadingAddActivity(true)
        setTimeout(() => {
            this.setState({
                selectedActivity: event.value,
                selectedActivityLabel: event.label
            })
            this.getMeasurements(event.value)
        }, 200);
    }

    getMeasurements(value) {

        let measurements = []
        this.state.activities.map((a) => {
            if (a.id == value) {
                measurements = a.unit
            }
        })

        this.setState({
            measurements: measurements,
            measurementSelectValue: measurements[0].id,
            measurementSelectLabel: measurements[0].unit,
            activity_loader: false
        })
    }

    handleMeasurementSelectValue(event) {
        this.setState({
            measurementSelectValue: event.value,
            measurementSelectLabel: event.label
        })
    }

    handleMeasurementValue(event) {

        this.setState({
            measurementValue: event.target.value
        })
    }


    async setLoadingTrackings(val) {
        this.setState({
            tracking_loader: val
        })
    }

    setTrackings(trackings) {
        this.setState({
            my_trackings: trackings
        })
    }

    setMeals(meals) {
        this.setState({
            meals: meals
        })
    }

    getMyTrackings() {
        this.state.sc.getMyTrackings(this.setTrackings, this.setLoadingTrackings, this.state.challenge_id)
    }

    addActivity() {

        let new_date = new Date(this.state.activity_date)
        let toStringDate = new_date.getFullYear().toString() + "-" + (new_date.getMonth() + 1).toString() + "-" + new_date.getDate().toString()

        let body = {
            "challenge_id": this.state.challenge_id,
            "activity_id": this.state.selectedActivity,
            "value": parseFloat(this.state.measurementValue),
            "unit_id": this.state.measurementSelectValue,
            "date": toStringDate
        }


        this.setLeaderboardLoading(true)
        this.setRegionLoading(true)
        let filters = {
            "is_global": this.state.global,
            "time": this.state.today == true ? 'day' : (this.state.week == true ? 'week' : '')
        }
        let filters_region = {
            "time": this.state.today_region == true ? 'day' : (this.state.week_region == true ? 'week' : '')
        }
        if (!this.state.measurementValue) {
            UseMessage("Inserire il valore dell'attività")
        } else if (this.state.measurementValue <= 0) {
            UseMessage("Inserire un valore maggiore di 0")
        } else if (!this.state.measurementValue.match(/^-?\d+$/) && !this.state.measurementValue.match(/^\d+\.\d+$/)) {
            UseMessage("Inserire un valore valido")
        } else {
            this.state.sc.addActivity(body, this.getBalance, this.getChallengeInfo, this.handleModal).then(() => {
                this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
                this.state.sc.getChallengeRegionLeaderboard(this.state.challenge_id, filters_region, this.setRegionLeaderboard, this.setRegionLoading)
            })
        }
    }

    deleteActivity() {
        this.setLeaderboardLoading(true)
        this.setRegionLoading(true)
        let filters = {
            "is_global": this.state.global,
            "time": this.state.today == true ? 'day' : (this.state.week == true ? 'week' : '')
        }
        let filters_region = {
            "time": this.state.today_region == true ? 'day' : (this.state.week_region == true ? 'week' : '')
        }
        this.state.sc.deleteActivity(this.state.selectedTracking.id, this.setLoadingTrackings, this.getMyTrackings, this.getBalance, this.getChallengeInfo, this.handleModal).then(() => {
            this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
            this.state.sc.getChallengeRegionLeaderboard(this.state.challenge_id, filters_region, this.setRegionLeaderboard, this.setRegionLoading)
        })
    }

    getChallengeLeaderboard(filters) {
        this.state.sc.getChallengeLeaderboard(this.state.challenge_id, filters, this.setLeaderboard, this.setLeaderboardLoading)
    }
    getTime(time) {
        return new Date(time).getTime()
    }

    getBalance() {
        this.state.sc.getBalance(this.state.challenge_id, this.setBalance, this.setLoading)
    }

    getChallengeInfo() {
        this.state.sc.getChallengeInfo(this.state.challenge_id, this.setChallenge)
    }

    setBalance(balance) {
        this.setState({
            balance: balance
        })
    }

    setConversionInfo(info) {
        this.setState({
            conversion_info: info
        })
    }

    datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }
    setInfoPopup(val) {
        this.setState({
            infoPopup: val
        })
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    setPrivacyPolicy(val) {
        this.setState({
            is_privacy_policy: val
        })
        sessionStorage.setItem('privacy_policy', false)
    }


    render() {

        let lang = localStorage.getItem('language')
        let join_challenge_button = 'join_challenge_button'
        let joined_challenge_button_class = 'joined_challenge_button'
        let balance = 'balance'
        let box_title_red = 'box_title_red text_center'
        let left_box_line_button = 'left_box_line_button'
        let workplace_logo = 'workplace_logo'

        if (lang === 'ko' || lang === 'es-MX' || lang === 'pt-BR') {
            join_challenge_button = 'join_challenge_button_ko'
        }
        if (lang === 'ja')
            join_challenge_button = 'join_challenge_button_ja'


        if (lang === 'ko') {
            joined_challenge_button_class = 'joined_challenge_button_ko'
            balance = 'balance_ko'
        }

        if (lang === 'ja') {
            box_title_red = 'box_title_red_ja text_center'
            joined_challenge_button_class = 'joined_challenge_button_ja'
            left_box_line_button = 'left_box_line_button_ja'
            workplace_logo = 'workplace_logo_ja'
        }

        if (lang === 'pt-BR') {
            workplace_logo = 'workplace_logo_pt'
            joined_challenge_button_class = 'joined_challenge_button_pt'
        }

        const customStylesSelect = {
            control: (base, state) => ({
                ...base,
                background: "#fff",
                color: "#000",
                border: 0,
                // Overwrittes the different states of border
                borderColor: "#fff !important",
                // Removes weird border around container
                boxShadow: "#fff !important",

            }),
            menuList: base => ({
                ...base,
                background: "#fff",
                // kill the white space on first and last option
                padding: 0,
                "overflow-x": "hidden",
                height: "220px",
                "::-webkit-scrollbar": {
                    width: "3px",
                    background: "#b8b8b8",
                    "border-radius": "32px"
                },
                "::-webkit-scrollbar-thumb": {
                    "background-color": "black",
                    "border- radius": "32px",
                },
            })
        };

        const customStylesMeasurementSelect = {
            control: (base, state) => ({
                ...base,
                background: "#fff",
                color: "#000",
                border: 0,
                // Overwrittes the different states of border
                borderColor: "#fff !important",
                // Removes weird border around container
                boxShadow: "#fff !important",

            }),
            menuList: base => ({
                ...base,
                background: "#fff",
                // kill the white space on first and last option
                padding: 0,
                "overflow-x": "hidden",
                height: "auto",
                "max-height": "200px",
                "::-webkit-scrollbar": {
                    width: "3px",
                    background: "#b8b8b8",
                    "border-radius": "32px"
                },
                "::-webkit-scrollbar-thumb": {
                    "background-color": "black",
                    "border- radius": "32px",
                },
            })
        };

        let customModalStyles = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: 'auto',
                height: '600px',
                marginRight: '-50%',
                padding: '30px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "border-radius": "30px"
            },

        };
        let seeAllModalStyles = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '500px',
                height: '600px',
                marginRight: '-50%',
                padding: '30px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "border-radius": "30px",
                overflow: 'hidden'
            },

        };

        let customModalStylesTracking = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '400px',
                height: '600px',
                marginRight: '-50%',
                padding: '30px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "border-radius": "30px",
                overflow: 'hidden'
            },

        };

        let leaderboard_users = ''
        if (this.state.leaderboard.length > 0) {
            leaderboard_users = this.state.leaderboard.map((u, index) =>
                (index != this.state.leaderboard.length || index == 0) && index < 10 ?
                    <div className="user_row">
                        <div className="user_rank">
                            <div className="user_rank_index">{index + 1}</div>
                        </div>
                        <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.user.image_url + ')' }}>
                            {!u.user.image_url ? <div className="user_rank_text">{u.user.first_name[0]}{u.user.last_name[0]}</div> : ''}
                        </div>
                        <div className="name_cont">
                            <div className="flex">
                                <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                                {/* <div className="user_surname"> </div> */}
                            </div>
                            <div className="location">{u.user.location}</div>
                        </div>
                        <div className="coins">
                            <div className="coins_value">{u.coins}</div>
                            <div className="coins_label location">{this.getTranslationByKey('CMC.COINS')}</div>
                        </div>
                    </div> : ''

            )
        }

        let leaderboard_users_modal = ''
        if (this.state.search_text === '' && this.state.modal_leaderboard.length > 0) {
            leaderboard_users_modal = this.state.modal_leaderboard.map((u, index) =>
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{this.state.currentPage < 1 ? index + 1 : (index + 1) + (this.state.page_items * (this.state.currentPage - 1))}</div>
                    </div>
                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                            {/* <div className="user_surname">{u.user.last_name} </div>*/}
                        </div>
                        <div className="location">{u.user.location}</div>
                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{this.getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div>
            )
        }
        else if (this.state.search_text && this.state.filtered_leaderboard.length > 0) {
            leaderboard_users_modal = this.state.filtered_leaderboard.map((u, index) =>
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{this.state.currentPage < 1 ? index + 1 : (index + 1) + (this.state.page_items * (this.state.currentPage - 1))}</div>
                    </div>
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.user.image_url + ')' }}>
                        {!u.user.image_url ? <div className="user_rank_text">{u.user.first_name[0]}{u.user.last_name[0]}</div> : ''}
                    </div>
                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                            {/*<div className="user_surname">{u.user.last_name} </div>*/}
                        </div>
                        <div className="location">{u.user.location}</div>
                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{this.getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div>)
        }
        else if (this.state.filtered_leaderboard.length == 0 || this.state.modal_leaderboard.length > 0) {
            leaderboard_users_modal = <div className="user_row">
                <div className="gucci_text_bold">{this.getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
            </div>
        }

        let my_team_users_modal = ''
        if (this.state.search_text === '' && this.state.modal_my_team.length > 0) {
            my_team_users_modal = this.state.modal_my_team.map((u, index) =>
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{this.state.currentPage < 1 ? index + 1 : (index + 1) + (this.state.page_items * (this.state.currentPage - 1))}</div>
                    </div>
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.image_url + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>
                    <div className="name_cont">
                        <div className="flex">
                            <div className="user_name">{u.first_name} </div>
                            <div className="user_surname">{u.last_name} </div>
                        </div>
                        <div className="location">{u.location}</div>
                    </div>

                </div>
            )
        }
        else if (this.state.search_text && this.state.filtered_my_team.length > 0) {
            my_team_users_modal = this.state.filtered_my_team.map((u, index) =>
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{index + 1}</div>
                    </div>
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.image_url + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>
                    <div className="name_cont">
                        <div className="flex">
                            <div className="user_name">{u.first_name} </div>
                            <div className="user_surname">{u.last_name} </div>
                        </div>
                        <div className="location">{u.location}</div>
                    </div>

                </div>)
        }
        else if (this.state.filtered_my_team.length == 0 || this.state.modal_my_team.length > 0) {
            my_team_users_modal = <div className="user_row">
                <div className="gucci_text_bold">{this.getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
            </div>
        }

        let activity_options = ''
        if (this.state.activities.length > 0)
            activity_options = this.state.activities.map((a) => <option value={a.id}>{a.name}</option>)
        let activity_options_new = []
        if (this.state.activities.length > 0)
            this.state.activities.map((a) => activity_options_new.push({ "value": a.id, "label": a.name }))
        let measurement_option = ''
        if (this.state.measurements.length > 0)
            measurement_option = this.state.measurements.map((a) => <option value={a.id}>{a.unit}</option>)
        let measurement_option_new = []
        if (this.state.measurements.length > 0)
            this.state.measurements.map((m) => measurement_option_new.push({ "value": m.id, "label": m.label }))
        let regex = new RegExp("^[0-9]+$")
        let regex2 = new RegExp("^[0-9]+\\.{0,1}[0-9]+$")
        return (
            <div className="background">
                <Navbar user={this.state.user} setPrivacyPopup={this.props.setPrivacyPopup} loading={this.setLoading} setPrivacyPolicy={this.setPrivacyPolicy} />
                {this.state.loading ?
                    <div className="loader-cont"><div className="loader"> </div></div>
                    :
                    this.state.is_privacy_policy ?

                        <div className="ppolicy_global_container">
                            <div className="ppolicy_container flex" onClick={() => this.setPrivacyPolicy(false)}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-chevron-left back_left_bar" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                                <div className="back_button_ppolicy">Back</div>
                                {/**/}
                            </div>
                            <div className="text_center">
                                <div className="ppolicy_title">{this.getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                                <div className="ppolicy_text gucci_text mt-3"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.getTranslationByKey('CMC.PRIVACYPOLICY.TEXT')) }}>

                                </div>
                            </div>
                        </div>

                        :
                        <div className={this.props.scroll ? "boxes_container boxes_container_animation flex" : 'boxes_container flex'}>
                            {/* first column */}
                            <div className="column">
                                <div Style="margin-bottom: 10px;">
                                    <div className="about_container">
                                        {/*
                                    <div className="unhcr_logo_small_container">
                                        <div id="unhcr_logo_small" Style={"background-image: url('" + this.state.challenge.image_url + "');"}></div>
                                    </div>
                                     */}
                                        <div className="text_center">
                                            <div className="logo_gucci_small_container"></div>
                                            <div className="about_title">{this.getTranslationByKey('CMC.ABOUT.CHALLANGE')}</div>
                                            <div className="box_text_ngo_challenge" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.challenge && this.state.challenge.description ? this.state.challenge.description : '') }}>
                                                { }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div Style="margin-bottom: 10px;">
                                    <div className="about_container">
                                        {/*
                                     */}
                                        <div className="unhcr_logo_small_container">
                                            <div id="unhcr_logo_small"></div>
                                        </div>
                                        <div className="text_center">
                                            <div className="about_title">{this.getTranslationByKey('CMC.ABOUT.NGO')}</div>
                                            <div className="box_text_ngo_challenge" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.challenge && this.state.challenge.ngo ? this.state.challenge.ngo.description : '') }}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* second column */}
                            <div className="column">
                                <div className="about_container_red" Style="margin-bottom: 10px;">
                                    <React.Fragment>
                                        <div className={box_title_red}>{this.getTranslationByKey('CMC.POWER.TITLE2')}</div>
                                        <div className="lines_container">
                                            <div className="left_box_line_container flex">
                                                <div className="left_box">
                                                    <div className="left_box_text">{this.getTranslationByKey('CMC.POWER.BLOCK5.VALUE')}</div>
                                                </div>
                                                <div className="box_text_blu ">
                                                    {this.getTranslationByKey('CMC.POWER.BLOCK5.TEXT')}</div>
                                            </div>
                                            <div className="left_box_line_container flex">
                                                <div className="left_box">
                                                    <div className="left_box_text">{this.getTranslationByKey('CMC.POWER.BLOCK6.VALUE')}</div>
                                                </div>
                                                <div className="box_text_blu ">
                                                    {this.getTranslationByKey('CMC.POWER.BLOCK6.TEXT')}</div>
                                            </div>
                                            <div className="left_box_line_container flex">
                                                <div className="left_box">
                                                    <div className="left_box_text">{this.getTranslationByKey('CMC.POWER.BLOCK7.VALUE')}</div>
                                                </div>
                                                <div className="box_text_blu ">
                                                    {this.getTranslationByKey('CMC.POWER.BLOCK7.TEXT')}</div>
                                            </div>
                                            <div className="left_box_line_container flex">
                                                <div className="left_box">
                                                    <div className="left_box_text">{this.getTranslationByKey('CMC.POWER.BLOCK8.VALUE')}</div>
                                                </div>
                                                <div className="box_text_blu ">
                                                    {this.getTranslationByKey('CMC.POWER.BLOCK8.TEXT')}</div>
                                            </div>
                                            <div className="left_box_line_container flex">
                                                <div className="left_box">
                                                    <div className="left_box_text">{this.getTranslationByKey('CMC.POWER.BLOCK9.VALUE')}</div>
                                                </div>
                                                <div className="box_text_blu ">
                                                    {this.getTranslationByKey('CMC.POWER.BLOCK9.TEXT')}</div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                                <div>
                                    <div className="about_container_red" Style="margin-bottom: 10px;" id='coin'>
                                        {this.state.joined ?

                                            <div className="text_center">
                                                <div className="about_container_header flex">
                                                    <div className="number_circle">{this.state.my_position > 0 ? this.state.my_position : ''}</div>
                                                    <div className="user_name_white">{this.state.user.firstname} {this.state.user.lastname}</div>
                                                </div>
                                                <div id="coin_container"></div>
                                                <div className="flex" Style={"align-items: center;justify-content: center;"}>
                                                    <div id={balance} className="flex">{this.getTranslationByKey('CMC.BALANCECOINS')}: {this.state.balance.total_balance}</div>
                                                    <div id="info_popup_icon" onClick={() => this.setInfoPopup(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="about_container_header flex" Style="width: 60%;">
                                                    <div id="my_coins">{this.state.balance.today_coins}</div>
                                                    <div id="coins_earned">{this.getTranslationByKey('CMC.COINSEARNEDTODAY')}</div>
                                                </div>
                                            </div>
                                            :
                                            <React.Fragment>
                                                <div className={box_title_red}>{this.getTranslationByKey('CMC.HOW.TITLE')}</div>
                                                <div className="text_container flex">
                                                    <div className="number_circle">1</div>
                                                    <div className="box_text ">{this.getTranslationByKey('CMC.HOW.LABLE1')}</div>
                                                </div>
                                                <div className="text_container flex">
                                                    <div className="number_circle">2</div>
                                                    <div className="box_text ">{this.getTranslationByKey('CMC.HOW.LABLE2')}</div>
                                                    <div className="info_ico" onClick={() => this.setInfoPopup(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="text_container flex">
                                                    <div className="number_circle">3</div>
                                                    <div className="box_text ">{this.getTranslationByKey('CMC.HOW.LABLE3')}</div>
                                                </div>


                                                <div className="flex" Style="justify-content: center;">
                                                    {/**/}
                                                    <div id="u266_claim">{this.getTranslationByKey('CMC.QUOTEMESSAGE')}</div>

                                                </div>
                                            </React.Fragment>
                                        }

                                        <div >
                                            {this.state.challenge && this.getTime(this.state.challenge.from) <= new Date().getTime() && new Date().getTime() <= this.getTime(this.state.challenge.to) ?
                                                (this.state.joined ?
                                                    <div className="flex">
                                                        <div className={joined_challenge_button_class} onClick={() => this.handleModal(true, 'activity')}>{this.getTranslationByKey('CMC.ADDACTIVITY')}</div>
                                                        <div className={joined_challenge_button_class} onClick={() => this.handleModal(true, 'tracking')}>{this.getTranslationByKey('CMC.MYTRACKING')}</div>
                                                    </div>
                                                    :
                                                    <div id={join_challenge_button} onClick={() => this.handleModal(true, 'join')}>{this.getTranslationByKey('CMC.JOINCHALLANGE')}</div>
                                                )
                                                :
                                                <div className={joined_challenge_button_class} onClick={() => this.handleModal(true, 'tracking')}>{this.getTranslationByKey('CMC.MYTRACKING')}</div>

                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* third column */}
                            <div className="column">

                                <div className="about_container_blu unhcr_challenge">
                                    <div id="u280">
                                    </div>
                                    <div Style="margin:5px 10px 0px 10px; height: 45px;" className="flex">
                                        <div className="box_title_blu" Style="margin-top: 0px;">{this.state.challenge.name}</div>
                                    </div>
                                    <ProgressBar value={this.state.challenge ? this.state.challenge.progress : (parseInt(this.state.challenge.target_value) / 2)} joined={this.state.joined} max={this.state.challenge ? parseInt(this.state.challenge.target_value) : 1000} />
                                    <div className="flex" Style="align-items: center; justify-content: center;">
                                        <div className="left_box_line_container under_progressbar">
                                            <div className="box_little_title" Style="margin-left: 0px">{this.getTranslationByKey('CMC.WEEKLYGOAL')}</div>
                                            <div className="box_little_title_2" Style="margin-left: 0px">{this.state.challenge ? parseInt(this.state.challenge.target_value) : ''} {this.getTranslationByKey('CMC.COINS')}</div>
                                        </div>
                                        <div className="flex pb_pd" Style="width: 190px;">
                                            {this.state.global_recently_joined.length > 0 ?
                                                this.state.global_recently_joined.map((leader) => (
                                                    leader.image_url ?
                                                        <div className="people_circle_user" Style={"background-image: url('" + leader.image_url + "'); background-size: cover;"}></div>
                                                        :
                                                        <div className="people_circle_user">
                                                            <div className="people_circle_text">{leader.first_name[0]}{leader.last_name[0]}</div>
                                                        </div>
                                                ))
                                                :
                                                <React.Fragment>
                                                    <div className="people_circle"></div>
                                                    <div className="people_circle"></div>
                                                    <div className="people_circle"></div>
                                                    <div className="people_circle"></div>
                                                </React.Fragment>
                                            }

                                        </div>
                                        <div className="pb_pd" Style="width: 100px; text-align: right;">
                                            <div className="box_little_title" Style="margin-bottom: 5px;">{this.state.challenge ? this.state.challenge.members_count : 0}</div>
                                            <div className="box_little_title" > {this.getTranslationByKey('CMC.COLLEAGUES')}</div>
                                        </div>
                                    </div>

                                    <br />
                                </div>

                                <div className="publish_container" Style="margin-top: 10px;">

                                    <div className="left_box_line_publish">
                                        {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                            <div id="publish_text" >{this.getTranslationByKey('CMC.PUBLISH.TEXT').replace('Workplace', 'Viva Engage')}</div>
                                            :
                                            <div id="publish_text" >{this.getTranslationByKey('CMC.PUBLISH.TEXT')}</div>
                                        }
                                        <div className={left_box_line_button} onClick={() => this.setPublishPopup(true)}>
                                            {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                                ''
                                                :
                                                <div id={workplace_logo}></div>
                                            }
                                            <div Style="margin-left: 15px; font-size: 20px;">{this.getTranslationByKey('CMC.PUBLISH')}</div>
                                        </div>


                                    </div>

                                </div>
                                <div className="donate_container" Style="margin-top: 10px;">
                                    <div className="left_box_line_container" Style="margin: 0px;">
                                        <div className="left_box_line_text">{this.getTranslationByKey('CMC.DONATE.MESSAGE')}</div>
                                        <div className={left_box_line_button} onClick={() => window.open(this.state.user.modules.gsc.donate_link, "_blank")}>
                                            {this.getTranslationByKey('CMC.DONATE')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* fourth column */}
                            <div className="column leaderboard_column">
                                <div Style="margin-bottom: 10px;">
                                    <div className="team_container mobile_team_container">
                                        <div className="team_box">
                                            <div className="box_title_container">
                                                {/*<div className="box_title">{this.getTranslationByKey('CMC.RECENTLYJOINED')}</div>*/}
                                                <div className="box_title">Team</div>
                                                {<div className="see_button pointer">See all</div>}
                                            </div>
                                            <div className="filters filters_mobile">
                                                {this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active ? '' : <div className={this.state.today_region ? "selected_button" : "unselected_button"} onClick={() => this.handleRegionButton('today')}>{this.getTranslationByKey('CMC.TODAY')}</div>}
                                                {this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active ? '' : <div className={this.state.week_region ? "selected_button" : "unselected_button"} onClick={() => this.handleRegionButton('week')}>{this.getTranslationByKey('CMC.THISWEEK')}</div>}
                                                <div className={this.state.month_region ? "selected_button" : "unselected_button"} onClick={() => this.handleRegionButton('month')}>{this.getTranslationByKey('CMC.ALL')}</div>
                                            </div>

                                            <div className="user_container recently_joined">
                                                {this.state.region_loading ?
                                                    <div className="loader-cont-recently"><div className="loader"> </div></div>
                                                    :
                                                    (this.state.regions.length > 0 ?
                                                        this.state.regions.map((r) =>
                                                            <div className="user_row">

                                                                <div className="user_row user_row_width">
                                                                    <div className="user_rank" Style={"margin-bottom: 0px; margin-top: 2px;"}>
                                                                        <div className="user_rank_index">{r.position}</div>
                                                                    </div>
                                                                    <div className="user_info">
                                                                        <div className="user_name"><span Style="text-transform: uppercase">{r.region}</span></div>
                                                                    </div>
                                                                    <div className="user_access">
                                                                        {r.date}
                                                                    </div>

                                                                    <div className="coins" Style={'margin-left: auto'}>
                                                                        <div className="coins_value">{r.coins}</div>
                                                                        <div className="coins_label location">{this.getTranslationByKey('CMC.COINS')}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <div className="gucci_text text_center" Style="margin-top: 20px">{this.getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="leaderboard_container mobile" >
                                    <div className="box_title_container">
                                        <div className="box_title">{this.getTranslationByKey('CMC.LEADERBOARD')}</div>
                                    </div>
                                    <div className="filters" Style="margin-left: 5px;">
                                        {this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active ? '' : <div className={this.state.today ? "selected_button" : "unselected_button"} onClick={() => this.handleButton('today')}>{this.getTranslationByKey('CMC.TODAY')}</div>}
                                        {this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active ? '' : <div className={this.state.week ? "selected_button" : "unselected_button"} onClick={() => this.handleButton('week')}>{this.getTranslationByKey('CMC.THISWEEK')}</div>}
                                        <div className={this.state.month ? "selected_button" : "unselected_button"} onClick={() => this.handleButton('month')}>{this.getTranslationByKey('CMC.ALL')}</div>
                                    </div>
                                    {this.state.leaderboard && this.state.leaderboard.length > 0 ?
                                        <React.Fragment>
                                            {this.state.leaderboard_loading ? <div className="loader-cont"><div className="loader"> </div></div> : <><div className="leaderboard_users">
                                                {leaderboard_users}
                                                {this.state.leaderboard.length > 10 ?
                                                    <div className="text_center">
                                                        <div className="see_button pointer" onClick={() => this.handleModal(true, 'see_all_leaderboard')}>{this.getTranslationByKey('CMC.SEEALL')}</div>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                            </>}
                                        </React.Fragment>
                                        :
                                        (this.state.leaderboard_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                                            <React.Fragment>
                                                <div className="waiting_steps_img"></div>
                                                <div className="gucci_text text_center">{this.getTranslationByKey('CMC.WAITINGFORFIRSTSTEPS')}</div>
                                            </React.Fragment>)
                                    }
                                </div>
                            </div>
                        </div >
                }

                <Modal
                    isOpen={this.state.openJoinChallengeModal}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'join')}
                    style={customModalStyles}
                    contentLabel="Join Challenge"
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">{this.getTranslationByKey('CMC.JOINCHALLANGE')}</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'join')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="gucci_text mt-3">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                        </div>
                    </div>

                </Modal>
                <Modal
                    isOpen={this.state.seeAllLeaderboardModalStatus}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'see_all_leaderboard')}
                    style={seeAllModalStyles}
                    className={'modal_base'}
                    contentLabel="Leaderboard"
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title"> {this.getTranslationByKey('CMC.LEADERBOARD')}</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'see_all_leaderboard')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <input type='text' className="input_activity mt-3" onChange={(e) => this.handleText(e)} placeholder='Search' />

                        <Pagination style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%' }} onChange={this.handleChangePage} page={this.state.currentPage}
                            count={this.state.search_text === '' ? ((parseInt(this.state.leaderboard.length / this.state.page_items) < (this.state.leaderboard.length / this.state.page_items) ? (parseInt(this.state.leaderboard.length / this.state.page_items) + 1) : parseInt(this.state.leaderboard.length / this.state.page_items)))
                                : ((parseInt(this.state.total_filtered_leaderboard.length / this.state.page_items) < (this.state.total_filtered_leaderboard.length / this.state.page_items) ? (parseInt(this.state.total_filtered_leaderboard.length / this.state.page_items) + 1) : parseInt(this.state.total_filtered_leaderboard.length / this.state.page_items)))} />

                        <div className="leaderboard_see_all_cont">
                            {this.state.filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                                (this.state.leaderboard.length > 0 && !this.state.leaderboard_loading && !this.state.filter_loading ? leaderboard_users_modal : '')}
                        </div>
                    </div>

                </Modal>
                <Modal
                    isOpen={this.state.seeAllTeamModalStatus}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'see_all_team')}
                    style={seeAllModalStyles}
                    contentLabel="Team"
                    className={'modal_base'}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">{this.getTranslationByKey('CMC.YOURTEAM')}</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'see_all_team')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <input type='text' className="input_activity mt-3" onChange={(e) => this.handleTextTeam(e)} placeholder='Search' />

                        <Pagination style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%' }} onChange={this.handleChangePageTeam} page={this.state.currentPage}
                            count={this.state.search_text === '' ? ((parseInt(this.state.my_team.length / this.state.page_items) < (this.state.my_team.length / this.state.page_items) ? (parseInt(this.state.my_team.length / this.state.page_items) + 1) : parseInt(this.state.my_team.length / this.state.page_items)))
                                : ((parseInt(this.state.total_filtered_my_team.length / this.state.page_items) < (this.state.total_filtered_my_team.length / this.state.page_items) ? (parseInt(this.state.total_filtered_my_team.length / this.state.page_items) + 1) : parseInt(this.state.total_filtered_my_team.length / this.state.page_items)))} />

                        <div className="leaderboard_see_all_cont">
                            {this.state.filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                                (this.state.my_team.length > 0 && !this.state.filter_loading ? my_team_users_modal : '')}
                        </div>
                    </div>

                </Modal>
                <Modal
                    isOpen={this.state.activityModalStatus}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'activity')}
                    //style={customModalStyles}
                    className={'activity_modal'}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">{this.getTranslationByKey('CMC.ADDACTIVITY')}</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'activity')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="w-100">
                            {this.state.user && this.state.user.modules && this.state.user.modules.gsc && this.state.user.modules.gsc.connected_to_strava ?
                                <div className="gucci_text text_center mt-4 w-100">
                                    {this.getTranslationByKey('CMC.STRAVACONNECTIONMESSAGE')}</div>
                                :
                                <React.Fragment><div className="gucci_text text_center mt-4 w-100">{this.getTranslationByKey('CMC.AUTOMATICIMPORT.MESSAGE')} </div>
                                    {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                        <div className="strava_button" onClick={() => window.open('https://www.strava.com/oauth/authorize?client_id=97095&response_type=code&redirect_uri=https://smartoffice-api.gucci.cn/api/strava/exchange_token/cn/' + this.state.user.id + '&approval_prompt=force&scope=activity:read_all', "_blank")}>{this.getTranslationByKey('CMC.CONNECTSTRAVA')} </div>
                                        :
                                        <div className="strava_button" onClick={() => window.open('https://www.strava.com/oauth/authorize?client_id=97095&response_type=code&redirect_uri=https://smartoffice.globalbit.gucci/api/strava/exchange_token/' + this.state.user.id + '&approval_prompt=force&scope=activity:read_all', "_blank")}>{this.getTranslationByKey('CMC.CONNECTSTRAVA')} </div>
                                    }
                                </React.Fragment>}
                            <div className="">
                                {this.state.activity_loader ?
                                    <div className="loader-cont"><div className="loader"> </div></div>
                                    :
                                    <div className="activity_form_container">
                                        <div className="activity_label">{this.getTranslationByKey('CMC.ACTIVITYTYPE')}</div>
                                        <Select
                                            value={this.state.selectedActivity}
                                            onChange={(e) => this.handleActivitySelect(e)}
                                            options={activity_options_new}
                                            className="select_activity"
                                            placeholder={this.state.selectedActivityLabel}
                                            styles={customStylesSelect}
                                        />
                                        <div className="add_activity_measurement_container">
                                            <div>
                                                <div className="activity_label">{this.getTranslationByKey('CMC.MEASUREMENTUNIT')}</div>
                                                <Select
                                                    value={this.state.measurementSelectValue}
                                                    onChange={(e) => this.handleMeasurementSelectValue(e)}
                                                    options={measurement_option_new}
                                                    className="select_activity"
                                                    placeholder={this.state.measurementSelectLabel}
                                                    styles={customStylesMeasurementSelect}
                                                />
                                            </div>
                                            <div>
                                                <div className="activity_label">{this.getTranslationByKey('CMC.MEASUREMENTVALUE')}</div>
                                                <input value={this.state.measurementValue} type='text' className="input_activity datepicker_modal" onChange={(e) => this.handleMeasurementValue(e)} />
                                                {this.state.measurementValue && regex.test(this.state.measurementValue) || regex2.test(this.state.measurementValue) ? '' :
                                                    <div className="gucci_text red">* Please insert number value (0.0) </div>}
                                            </div>
                                        </div>

                                        <div className="activity_label">{this.getTranslationByKey('CMC.DATE')}</div>
                                        <div className="activity_date_input">
                                            <DatePicker
                                                selected={this.state.activity_date}
                                                onChange={(date) => this.setActivityDate(date)}
                                                onSelect={(date) => this.setActivityDate(date)}
                                                placeholderText="gg/mm/aaaa"
                                                minDate={new Date(this.state.challenge.from)}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                className="date_picker_activity datepicker_modal"

                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar activity_date_icon" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                            </svg>
                                        </div>
                                        <div className="w-100">
                                            <div className="confirm_add_activity_button" onClick={() => this.addActivity()}>{this.getTranslationByKey('CMC.ADD')}</div>
                                            <div className="cancel_button" onClick={() => this.handleModal(false, 'activity')}>{this.getTranslationByKey('CMC.CANCEL')}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal >

                <Modal
                    isOpen={this.state.trackingModalStatus}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'tracking')}
                    className={'modal_base'}
                    //style={customModalStylesTracking}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">{this.getTranslationByKey('CMC.MYTRACKING')}</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'tracking')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="modal_content_subtitle">
                            {this.getTranslationByKey('CMC.ACTIVITYHISTORY')}
                        </div>
                        <div className="modal_activity_list_container">
                            <div className="modal_activity_list">
                                {this.state.tracking_loader ?
                                    <div className="loader-cont"><div className="loader"> </div></div>
                                    :
                                    (this.state.my_trackings.length > 0 ?
                                        this.state.my_trackings.map((activity) =>
                                            <div className="activity_line flex">
                                                {activity.image_url ?
                                                    <div className="activity_icon_image_present" Style={"background-image: url('" + activity.image_url + "');"}></div>
                                                    :
                                                    <div className="activity_icon_image">{activity.name[0]}</div>}
                                                <div className="activity_list_text_container">
                                                    <div className="activity_list_text bold flex">{activity.name} | {activity.value} {activity.unit.unit} | {activity.coins} {this.getTranslationByKey('CMC.COINS')}</div>
                                                    <div className="activity_list_text" Style="font-size: 14px">{this.formatData(activity.date)}</div>
                                                </div>
                                                {this.props.user && this.props.user.modules && this.props.user.modules.gsc && !this.props.user.modules.gsc.challenges[0].is_active ? '' : (
                                                    activity.can_delete ?
                                                        <div>
                                                            <svg onClick={() => this.handleModal(true, 'delete', activity)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash delete_activity pointer" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="grey" class="bi bi-trash delete_activity" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                        </div>
                                                )}
                                            </div>
                                        )
                                        :
                                        <div className="gucci_text text_center" Style="margin-top: 20px">{this.getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
                                    )}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.infoPopup}
                    onAfterOpen={null}
                    onRequestClose={() => this.setInfoPopup(false)}
                    style={customModalStylesTracking}
                    className={'modal_base'}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title"></div>
                            <div className="close_button" >
                                <svg onClick={() => this.setInfoPopup(false)} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="info_popup_container">
                            <table className="info_popup_table">
                                <tr>
                                    <th className="info_popup_table_header" colSpan={2}>{this.getTranslationByKey('CMC.ACTIVITYTYPE')}</th>
                                    <th className="info_popup_table_header">{this.getTranslationByKey('CMC.MEASUREMENTUNIT')}</th>
                                    <th className="info_popup_table_header">{this.getTranslationByKey('CMC.COINS')}</th>
                                </tr>
                                {this.state.conversion_info.length > 0 ?
                                    this.state.conversion_info.map((elem) =>
                                        <tr className="info_popup_table_row">
                                            <td colSpan={2} className="info_popup_table_cell">
                                                <div className="info_popup_table_cell_image">
                                                    <div className="activity_image_container" style={{ backgroundImage: 'url(' + elem.image_url + ')' }}></div>
                                                    {elem.name}
                                                </div>
                                            </td>
                                            <td className="info_popup_table_cell">{elem.conversion} {elem.unit}</td>
                                            <td className="info_popup_table_cell">{elem.coins}</td>
                                        </tr>
                                    )
                                    : null}

                            </table>
                        </div>

                    </div>
                </Modal>
                <PhotoPopup publishPopup={this.state.publishPopup} setPublishPopup={this.setPublishPopup} getTranslationByKey={this.getTranslationByKey} ></PhotoPopup>

                <Modal
                    isOpen={this.state.modal_delete_tracking}
                    onAfterOpen={null}
                    onRequestClose={() => this.handleModal(false, 'delete', '')}
                    style={customModalStylesTracking}
                    className={'modal_base confirm_delete'}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">Remove activity</div>
                            <div className="close_button" >
                                <svg onClick={() => this.handleModal(false, 'delete', '')} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="info_popup_container">
                            Do you want to delete your activity?

                            <div className="mt-10">
                                <div className="bold">Activity: <span className="light"> {this.state.selectedTracking.name} </span></div>
                                <div className="bold">Value:  <span className="light">{this.state.selectedTracking.value}</span></div>
                                <div className="bold">Coins:  <span className="light">{this.state.selectedTracking.coins}</span></div>
                                <div className="bold">Date:  <span className="light"> {this.formatData(this.state.selectedTracking.date)}</span></div>
                            </div>
                            <div className="w-100 flex" Style="margin-top: 40px">
                                <div className="cancel_delete_button" onClick={() => this.handleModal(false, 'delete', '')}>{this.getTranslationByKey('CMC.CANCEL')}</div>
                                <div className="confirm_delete_activity_button" onClick={() => this.deleteActivity()}>Delete</div>
                            </div>
                        </div>

                    </div >
                </Modal >

            </div >


        )
    }

}

export default Homepage;