import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Navbar from "./Navbar"
import { SolidarityChallengeService } from "../services/Services";
import DOMPurify from 'dompurify'
import Homepage2 from "./Homepage2";
import Countdown from 'react-countdown';
const Login = (props) => {

    const [user, setUser] = useState('')
    const [loading, setLoading] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    const [challengeStatus, setChallengeStatus] = useState('')
    const [challenge, setChallenge] = useState('')
    const [preChallenge, setPreChallenge] = useState(false)
    const [isAccepted, setisAccepted] = useState(false)
    const [isAcceptedError, setisAcceptedError] = useState(false)
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        // Render a countdown
        return (
            <div className="flex">
                <div className="countdown_columns">
                    <div className="countdown_counter_text">{zeroPad(days, 2)} </div>
                    <div className="countdown_text" >days</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(hours, 2)}</div>
                    <div className="countdown_text" >hours</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(minutes, 2)}</div>
                    <div className="countdown_text" >minutes</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(seconds, 2)} </div>
                    <div className="countdown_text" >seconds</div>
                </div>

            </div>
        )
    };

    const zeroPad = (num, numZeros) => {
        var n = Math.abs(num);
        var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
        var zeroString = Math.pow(10, zeros).toString().substr(1);
        if (num < 0) {
            zeroString = '-' + zeroString;
        }
        return zeroString + n;
    }

    const set = (user) => {
        //remove 2 lines to enable entered flag
        // if (user && user.modules && user.modules.gsc && user.modules.gsc.entered)
        //    user.modules.gsc.entered = false
        setUser(user)
        if (user && user.modules && user.modules.gsc && user.modules.gsc.challenges && user.modules.gsc.challenges[0]
            && user.modules.gsc.challenges[0])
            setChallenge(user.modules.gsc.challenges[0])
        let challenge = 'active'
        if (user && user.modules && user.modules.gsc && user.modules.gsc.challenges.length > 0) {

            let sessionEntered = sessionStorage.getItem('is_entered')

            if (user.modules.gsc.challenges[0].from && new Date(user.modules.gsc.challenges[0].from).getTime() > new Date().getTime()) {

                setPreChallenge(true)
            }
            else if (user.modules.gsc.challenges[0].is_active) {
                if (user.modules.gsc.entered) {
                    setLoading(false)
                    ReactDOM.render(<Homepage2 userReload={userReload} scroll={true} user={user} privacyPopup={props.privacyPopup} setPrivacyPopup={props.setPrivacyPopup} />, document.getElementById('root'))
                }
            } else {
                challenge = 'just_closed'
                sessionStorage.setItem('is_entered', false)
                // if (sessionEntered) {
                setLoading(false)
                //     ReactDOM.render(<Homepage scroll={true} user={user} privacyPopup={props.privacyPopup} setPrivacyPopup={props.setPrivacyPopup} />, document.getElementById('root'))
                //   }
            }
        } else if (user.modules && user.modules.gsc && user.modules.gsc.challenges && user.modules.gsc.challenges.length == 0) {
            challenge = 'closed'
            setLoading(false)
        }

        setTimeout(() => {
            setChallengeStatus(challenge)
        }, 100);
    }
    useEffect(() => {

        let path = window.location.href.split('staging/')
        let original_path = path[0] + 'staging/'
        if (window.location.hostname === 'changemakerschallenge.gucci.cn') {
            path = window.location.href.split('.cn/')
            original_path = path[0] + '.cn/'
        } else if (window.location.hostname === 'changemakerschallenge.gucci.com') {
            path = window.location.href.split('.com/')
            original_path = path[0] + '.com/'
        }

        if (path.length > 1 && path[1] === '?privacy_policy') {
            setPrivacyPolicy(true)
            window.history.pushState('login', 'login', original_path)
        }
        sessionStorage.setItem('privacy_policy', false)

        if (props.isAuthenticated) {
            setLoading(true)
            let service = new SolidarityChallengeService()
            service.getUserInfo(set, setLoading)
        }
    }, [props.isAuthenticated]);

    const handleLogin = () => {
        //ReactDOM.render(<Homepage />, document.getElementById('root'));
        /*   this.setLoading(true).then(() => {

               msalInstance.loginRedirect(loginRequest).catch(e => {
                   console.log(e)
               }).finally(e => {
                   this.setLoading(false)
               });
           })*/

        if (!isAccepted && challengeStatus !== 'just_closed') {
            setisAcceptedError(true)
        } else
            if (props.isAuthenticated) {
                setLoading(true)
                let service = new SolidarityChallengeService()
                service.setEntered(setLoading)
                // sessionStorage.setItem('is_entered', true)
                ReactDOM.render(<Homepage2 userReload={userReload} user={user} privacyPopup={props.privacyPopup} setPrivacyPopup={props.setPrivacyPopup} />, document.getElementById('root'))
            }
    }


    function getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }
    function getChallengeDuration() {
        /*   let from = new Date(challenge.from)
           let to = new Date(challenge.to)
           let s = from.getDate() + (from.getMonth() != to.getMonth() ? ' ' + from.toLocaleString('en-us', { month: 'short' }) : '') + (from.getFullYear() != to.getFullYear() ? from.getFullYear() : '') + ' - ' + to.getDate() + ' ' + to.toLocaleString('en-us', { month: 'short' }) + ' ' + to.getFullYear()
   */
        let s = '27 MAY - 30 JUNE 2024'
        return s
    }

    function handleIsAcceptedCheckbox() {
        setisAcceptedError(false)
        setisAccepted(!isAccepted)
    }
    function userReload() {
        let service = new SolidarityChallengeService()
        service.getUserInfo(set, setLoading)
    }

    return (
        props.isAuthenticated && !loading ?
            <div className="login">
                <Navbar user={user} setPrivacyPolicy={setPrivacyPolicy} />
                {user.modules ?
                    !user.modules.gsc ?
                        <div className="login_container">
                            <div className="login_info_container">
                                <div className="not_auth_container">
                                    <div className="not_auth_message">{getTranslationByKey('CMC.NOTAUTHORIZED')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :

                        user.modules.gsc.entered ?
                            (challengeStatus === 'active' ?
                                null
                                :
                                <React.Fragment>
                                    <div className="login_container">
                                        <div className="login_info_container">
                                            <div>
                                                {/**/}
                                                <div className="logos_container">
                                                    <div className="flex" Style="justify-content: center; align-items: center;">
                                                        <div className="gucci_logo_container"></div>
                                                        {/*<div className="logo_separator">x</div>*/}
                                                        <div className="unhcr_logo_container"></div>
                                                    </div>
                                                </div>    <div className="up_images_cont">
                                                    <div className="image1"></div>
                                                    <div className="image2"></div></div>
                                                <div className="main_title_container">
                                                    <div className="login_main_title">{/*getTranslationByKey('CMC.MAINTITLE')*/}
                                                        <img className="pedal_logo" src={require('../resources/img/pedal_to_empower_logo.png')} /></div>
                                                </div>

                                                {challengeStatus === 'active' ? <div className="login_ppolicy pointer" onClick={() => setPrivacyPolicy(true)}>{getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                                                    : ''}
                                                <div className="title_container">
                                                    <div className="login_main_text" dangerouslySetInnerHTML={{ __html: challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.TITLE') : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TITLE') : getTranslationByKey('CMC.INTRO.INACTIVE.TITLE')) }}></div>
                                                    <br />
                                                    <div className="login_text">
                                                        {challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.TEXT1') : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TEXT1') : '')}
                                                        <br />
                                                        {challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.TEXT2') : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TEXT2') : '')}
                                                    </div>
                                                    <br />
                                                </div>
                                                {challengeStatus === 'closed' ?
                                                    <div Style={"height: 100px;"}></div> :
                                                    <React.Fragment>
                                                        {/*<div className="isAcceptedCheckbox_container">
                                                            <input checked={isAccepted} type="checkbox" id="isAcceptedCheckbox" name="isAcceptedCheckbox" onChange={() => handleIsAcceptedCheckbox()} />
                                                            <label id="isAcceptedCheckbox_label" Style={"padding-left: 10px;"} >
                                                                <span className="flex">
                                                                    <div className="label_checkbox_link pointer" dangerouslySetInnerHTML={{ __html: getTranslationByKey('CMC.TERMSANDCONDITIONS.TEXT') }} ></div></span></label>
                                                        </div>
                                                        {isAcceptedError ? <div>{getTranslationByKey('CMC.TERMSANDCONDITIONS.ERROR')}</div> : <div Style={"min-height: 30px;"}></div>}
                */ }
                                                        {challengeStatus === 'active' ? <div className="login_ppolicy pointer" onClick={() => setPrivacyPolicy(true)}>{getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                                                            : ''}
                                                        {challengeStatus !== 'closed' ?
                                                            <div className="login_button_mobile" onClick={handleLogin}>
                                                                <div className="login_button_text">
                                                                    {challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.BUTTON') : getTranslationByKey('CMC.INTRO.JUSTFINISH.BUTTON')}
                                                                </div>
                                                            </div> : null
                                                        }
                                                    </React.Fragment>
                                                }
                                                <div className="login_images_container">
                                                    <div className="image3"></div>
                                                    <div className="image4"></div>
                                                </div>
                                                {/*
                                                    <div className="login_button" onClick={handleLogin}>
                                                        <div className="login_button_text">
                                                            {getTranslationByKey('CMC.INTRO.BUTTON')}
                                                        </div>
                                                    </div>
                                                    */}</div>
                                            {/*<div className="u424_container"></div>*/}
                                        </div>
                                    </div >
                                </React.Fragment>
                            )
                            :

                            //posso togliere tutto quello che sta sopra
                            (privacyPolicy ?
                                <div className="background" Style="margin-top: -25px;height: calc(100vh - 50px);">
                                    <div className="ppolicy_global_container">
                                        <div className="ppolicy_container flex" onClick={() => setPrivacyPolicy(false)}>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-chevron-left back_left_bar" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <div className="back_button_ppolicy">Back</div>
                                            {/**/}
                                        </div>
                                        <div className="text_center">
                                            <div className="ppolicy_title">{getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                                            <div className="ppolicy_text gucci_text mt-3"
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.PRIVACYPOLICY.TEXT')) }}>

                                            </div>
                                        </div>
                                    </div></div>
                                :

                                user && user.modules.gsc && !loading ?
                                    <React.Fragment>
                                        <div className="login_container">

                                            <div className="login_info_container">
                                                <div>
                                                    {/**/}
                                                    <div className="logos_container">
                                                        <div className="flex" Style="justify-content: center; align-items: center;">
                                                            <div className="gucci_logo_container"></div>
                                                            {/*<div className="logo_separator">x</div>*/}
                                                            <div className="unhcr_logo_container"></div>
                                                        </div>
                                                    </div>
                                                    <div className="up_images_cont">
                                                        <div className="image1"></div>
                                                        <div className="image2"></div></div>
                                                    <div className="main_title_container">
                                                        <div className="login_main_title">{/*getTranslationByKey('CMC.MAINTITLE')*/}
                                                            <img className="pedal_logo" src={require('../resources/img/pedal_to_empower_logo.png')} /></div>
                                                    </div>



                                                    <div className="title_container">
                                                        <div className="login_main_text" dangerouslySetInnerHTML={{
                                                            __html: challengeStatus === 'active' ?

                                                                (challenge ? getChallengeDuration() : '')
                                                                : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TITLE') : getTranslationByKey('CMC.INTRO.INACTIVE.TITLE'))
                                                        }}></div>
                                                        <br />
                                                        {challengeStatus === 'active' ? <div Style={'text-transform:uppercase;margin-bottom:5px; font-size:18px'} dangerouslySetInnerHTML={{ __html: getTranslationByKey('CMC.INTRO.INPROGRESS.TITLE2') }}></div> : ''}

                                                        <div className="login_text" dangerouslySetInnerHTML={{
                                                            __html:
                                                                challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.TEXT3') : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TEXT1') : '')
                                                        }}></div>
                                                        <br />
                                                        <div className="login_text" dangerouslySetInnerHTML={{
                                                            __html: challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.TEXT4') : (challengeStatus === 'just_closed' ? getTranslationByKey('CMC.INTRO.JUSTFINISH.TEXT2') : '')
                                                        }}>
                                                        </div>
                                                    </div>
                                                    {challengeStatus === 'closed' ?
                                                        <div Style={"height: 100px;"}></div>
                                                        :
                                                        <React.Fragment>
                                                            {!preChallenge ? <div className="w-85">
                                                                <div className="isAcceptedCheckbox_container">
                                                                    <input checked={isAccepted} type="checkbox" id="isAcceptedCheckbox" name="isAcceptedCheckbox" onChange={() => handleIsAcceptedCheckbox()} />
                                                                    <label id="isAcceptedCheckbox_label" Style={"padding-left: 10px;"} ><span className="flex">{/*getTranslationByKey('CMC.TERMSANDCONDITIONS.TEXT')*/}
                                                                        <div className="label_checkbox_link pointer" dangerouslySetInnerHTML={{ __html: getTranslationByKey('CMC.TERMSANDCONDITIONS.TEXT') }}></div></span></label>
                                                                </div>
                                                            </div> : ''}
                                                            {isAcceptedError ? <div className="terms_error"> {getTranslationByKey('CMC.TERMSANDCONDITIONS.ERROR')}</div> : <div Style={"min-height: 30px;"}></div>}
                                                            {challengeStatus === 'active' ? <div className="login_ppolicy pointer" Style={!preChallenge ? '' : 'margin-top:-20px'} onClick={() => setPrivacyPolicy(true)}>{getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                                                                : ''}
                                                            {!preChallenge ? <div>
                                                                <div className="login_button_mobile" onClick={handleLogin}>

                                                                    <div className="login_button_text">
                                                                        {challengeStatus === 'active' ? getTranslationByKey('CMC.INTRO.INPROGRESS.BUTTON') : getTranslationByKey('CMC.INTRO.JUSTFINISH.BUTTON')}
                                                                    </div>
                                                                </div>
                                                            </div> : ''}
                                                            {!preChallenge && challenge.countdown_date ? '' :
                                                                <div className="w-50">

                                                                    <div className='cd' Style={'margin-top:20px; font-family:"GucciSans-Book"'}>
                                                                        <Countdown
                                                                            date={new Date(challenge.countdown_date)}
                                                                            renderer={renderer}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    <div className="login_images_container">
                                                        <div className="image3"></div>
                                                        <div className="image4"></div>
                                                    </div>
                                                    {/*
                                                    <div className="login_button" onClick={handleLogin}>
                                                        <div className="login_button_text">
                                                            {getTranslationByKey('CMC.INTRO.BUTTON')}
                                                        </div>
                                                    </div>
                                                    */}</div>
                                                {/*<div className="u424_container"></div>*/}
                                            </div>
                                        </div >
                                    </React.Fragment>
                                    :
                                    (!loading ?
                                        <div className="not_auth_container">
                                            <div className="not_auth_message">{getTranslationByKey('CMC.NOTAUTHORIZED')}
                                            </div>
                                        </div>
                                        :
                                        <div className="not_auth_container">
                                            <div className="not_auth_message">
                                                <div className="loader-cont"><div className="loader"> </div></div>
                                            </div>
                                        </div>)

                            )

                    :
                    <div className="login_container">
                        <div className="login_info_container">
                            <div className="not_auth_container">
                                <div className="not_auth_message">{getTranslationByKey('CMC.NOTAUTHORIZED')}
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div >
            :
            <div className="not_auth_container">
                <div className="not_auth_message">
                    <div className="loader-cont"><div className="loader"> </div></div>
                </div>
            </div>
    )

}

export default Login;