import React from 'react';
import { useState, useEffect } from 'react';


import { ProgressBar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Progressbar(props) {

    function getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    // console.log(props.value)
    let percentage = (props.value * 100) / props.max
    return (
        <> <div className='progress_bar_balloon'>
            {/*   {!props.joined ?
                <div className='progress_label_balloon' style={{ marginLeft: 'calc(' + (percentage > 92 ? 92 : (percentage < 8 ? 8 : percentage)) + '% - 125px)' }}>
                    <div className='balloon_label_text_l'>{props.value}</div>
         </div>
                : */}<div className='progress_label_balloon' style={{ marginLeft: 'calc(' + (percentage > 92 ? 92 : (percentage < 8 ? 8 : percentage)) + '% - 42px)' }}>
                <div className='balloon_label_text'>{props.value + ' ' + getTranslationByKey('CMC.COINS')}</div>
            </div>


            <svg width="85px" height="35px" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 'calc(' + (percentage > 92 ? 92 : (percentage < 8 ? 8 : percentage)) + '% - 42px)' }}>
                <g transform="matrix(1 0 0 1 -100 -350 )">
                    <path d="M 0 21  L 0 7  C 0 3.0800000000000636  3.1166666666666507 0  7.083333333333333 0  L 77.91666666666666 0  C 81.88333333333334 0  85 3.0800000000000636  85 7  L 85 21  C 85 24.919999999999938  81.88333333333334 28  77.91666666666666 28  L 50.055555555555614 28  L 42.73611111111105 35  L 35.65277777777772 28  L 7.083333333333333 28  C 3.1166666666666507 28  0 24.919999999999938  0 21  Z " fill-rule="nonzero" fill="#ffffff" stroke="none" fill-opacity="0.4980392156862745" transform="matrix(1 0 0 1 100 350 )" />
                </g>
            </svg>

        </div>
            <div className="progressBar" Style="margin: 10px; margin-bottom: 15px;">
                <ProgressBar now={percentage} />
            </div>
        </>
    );
};

