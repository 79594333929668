import React, { Component } from "react";
import Cookies from "universal-cookie";
import { msalInstance } from "../authConfig";
import Modal from 'react-modal';
import { Dropdown } from 'react-bootstrap';
class Navbar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openPopup: false,
            cookies: new Cookies(),
            languages: [
                { name: 'chinese', id: 'zh-Hans' },
                { name: 'english', id: 'en' },
                { name: 'italian', id: 'it' },
                { name: 'korean', id: 'ko' },
                { name: 'japan', id: 'ja' },
                { name: 'spanish', id: 'es-MX' },
                { name: 'portuguese', id: 'pt-BR' },],
            selected_language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        }

        this.chooseLanguageMenu = this.chooseLanguageMenu.bind(this)
        this.closeLanguageMenu = this.closeLanguageMenu.bind(this)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.confirmLanguage = this.confirmLanguage.bind(this)
    }

    logout() {
        let cookies = new Cookies();
        cookies.remove("user_token_type", { path: '/' })
        cookies.remove("user_token", { path: '/' })
        localStorage.clear()
        sessionStorage.clear()

        msalInstance.logoutRedirect().catch(e => {
        });
    }

    chooseLanguageMenu(e) {
        e.preventDefault()
        this.setState({
            openPopup: true,
            selected_language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        })
    }

    closeLanguageMenu() {
        this.setState({
            openPopup: false
        })
    }

    confirmLanguage() {

        localStorage.setItem('language', this.state.selected_language)
        this.setState({
            openPopup: false
        })
        window.location.reload()
    }

    selectLanguage(index) {
        this.setState({
            selected_language: this.state.languages[index].id
        })
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    render() {

        let customStyle = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '300px',
                height: '420px',
                marginRight: '-50%',
                padding: '30px',
                transform: 'translate(-50%, -50%)',
                'z-index': '99',
                transition: "opacity 20ms ease-in -out",
                "border-radius": "30px",
                overflow: 'hidden',
                'background-color': 'white',
                position: 'relative'
            },

        };
        let languages = ''
        languages = this.state.languages.map((l, index) => <div className="language_row flex">
            <input type="radio" id={l.name} name="fav_language" value="English" defaultChecked={this.state.selected_language === l.id ? true : false} onChange={() => this.selectLanguage(index)} />
            <div for={l.name} className="gucci_text self_center">{l.name}</div>
        </div>
        )
        return (
            <div className="login_navbar flex">
                <div className="navbar_text">{this.getTranslationByKey('CMC.MAINTITLE')}</div>
                {this.props.user && this.props.user.modules && this.props.user.modules.gsc ?
                    <React.Fragment>
                        <div className="navbar_logo"></div>
                        <div className="user_nav_ico">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <div className="user_nav_ico flex">
                                        <div className="user_nav_img" style={{ backgroundImage: 'url(' + this.props.user.image_url + ')' }}>
                                            {this.props.user && !this.props.user.image_url ? <div className="user_nav_text">{this.props.user.firstname[0]}{this.props.user.lastname[0]}</div> : ''}
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                    {this.props.user && this.props.user.modules.gsc ? <Dropdown.Item className="dropdown-item" onClick={() => this.props.setPrivacyPolicy(true)} key="1">{this.getTranslationByKey('CMC.PRIVACYPOLICY')}</Dropdown.Item> : ''}
                                    {this.props.user && this.props.user.modules.gsc ? <Dropdown.Item className="dropdown-item " href={this.getTranslationByKey('CMC.SIGNEDWAIVER.LINK')} target='_blank'>{this.getTranslationByKey('CMC.TERMSANDCONDITIONS')}</Dropdown.Item> : ''}
                                    {this.state.cookies.get('user_token') === undefined ? '' : <Dropdown.Item className="dropdown-item" onClick={this.logout} key="2">{this.getTranslationByKey('CMC.LOGOUT')}</Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="language_text" onClick={(e) => this.chooseLanguageMenu(e)}></div>
                        <div className="dropdown navbar_dropdown flex">
                            <div onClick={(e) => this.chooseLanguageMenu(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" class="bi bi-globe pl-5" viewBox="0 0 16 16">
                                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                                </svg></div>
                        </div>
                        {/* */}

                    </React.Fragment >
                    :
                    <div className="logout_icon">
                    </div>
                }

                <Modal
                    isOpen={this.state.openPopup}
                    onAfterOpen={null}
                    onRequestClose={(e) => this.chooseLanguageMenu(e)}
                    //style={customStyle}
                    className={'modal_popup modal_base_languages'}
                    appElement={document.getElementById('root') || undefined}
                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">
                            <div className="modal_content_title">{this.getTranslationByKey('CMC.LANGUAGES')}</div>
                            <div className="close_button" >
                                <svg onClick={this.closeLanguageMenu} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                        <div className="languages_cont">
                            {languages}
                            <div>
                                <div className="cancel_button_s" onClick={this.closeLanguageMenu} >{this.getTranslationByKey('CMC.CANCEL')}</div>
                                <div className="confirm_button_s" onClick={this.confirmLanguage}  >{this.getTranslationByKey('CMC.CONFIRM')}</div>
                            </div>
                        </div>
                    </div>
                </Modal>


            </div >

        )
    }

}

export default Navbar;