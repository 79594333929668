import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import Cookies from "universal-cookie";

//alert for 401 message
export function UseAlert(props) {

    const message = useToast({
        message: 'Your credentials are not valid!',
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: 'error',
        color: '#b90000'
    });

    if (props === 401) {
        let cookies = new Cookies();
        cookies.remove("user_token_type", { path: '/' })
        cookies.remove("user_token", { path: '/' })
        localStorage.clear()
        sessionStorage.clear()
        // ReactDOM.render(<App />, document.getElementById('root'));
        return '';
    }
}
//alert with custom message
export function UseMessage(props) {

    const message = useToast({
        message: props,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: 'error',
        color: '#b90000',
        messageColor: '#fff'
    });
    return message();
}

//alert with custom message
export function UseMessageLogin(props, title) {

    const message = useToast({
        message: props,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: 'error',
        color: '#b90000',
        messageColor: '#272727'
    });
    return message();
}


//alert with custom message
export function UseAlertMessageLogin(status, mess) {

    const message = useToast({
        message: mess,
        position: "bottomRight",
        class: status == 200 ? "btn btn-outline-success" : "btn btn-outline-error",
        timeout: 7000,
        progressBar: true,
        icon: status == 200 ? 'success' : 'warning',
        color: status == 200 ? '#c04342' : '#b90000',
        messageColor: status == 200 ? '#000' : '#fff'
    });
    return message();
}

//alert with custom status error and message
export function UseAlertMessage(status, mess) {

    const message = useToast({
        message: mess,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: status == 200 ? 'success' : 'warning',
        color: '#00c64a73'
    });
    return message();
}
